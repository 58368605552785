<template lang="pug">
    .main-wrapper.empresa-visualizar
            MostrarPaciente(:waitingPaciente='waitingPaciente' :model='modelUser' :isTitular = "selectedPai" :empresaId='this.model.id' @close='dialogUser.visible=false' :dialogPaciente='dialogUser.visible')
    
            DialogHistoricoCorrentista(
                :visible='dialogHistoricoCorrentista.visible'
                @close='dialogHistoricoCorrentista.visible=false'
                :model='dialogHistoricoCorrentista.data'
            )
    
            Dialog.dialogApagar(header='Remover Usuário' :visible.sync='dialogApagar' :modal='true')
                p Deseja remover o usuario <b>{{ dialogApagar_data?.nm_pessoa_fisica }}</b>?
                .ta-center.my-4
                    ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                    Button.p-button-danger(v-else label='Remover' @click='excluirCorrentista()')
    
            ProgressBar(v-if='waiting' mode="indeterminate")
            div(v-else)
                header.main-wrapper-header
                    .p-grid.p-align-center
                        .p-col-3
                            Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                        .p-col-9.ta-right
                            h1.text-header.text-secondary MedClub / Empresa / <b>Visualizar</b>
    
                div
                    ProgressBar(v-if='waiting' mode="indeterminate")
                    div(v-else)
                        Panel(header="Dados Empresa")
                            .p-grid.p-fluid.p-align-end
    
                                .p-col-12.p-md-3
                                    label.form-label Razão social:
                                    InputText(type='text' v-model='model.nm_razao_social' readonly)
    
                                .p-col-12.p-md-3
                                    label.form-label CNPJ:
                                    InputText(mask='99.999.999/9999-99' v-model='model.nr_cnpj_f' readonly)
    
                                .p-col-12.p-md-3
                                    label.form-label Nome fantasia:
                                    InputText(type='text' v-model='model.nm_fantasia' readonly)
    
                                .p-col-12.p-md-3
                                    label.form-label Responsável:
                                    InputText(type='text' v-model='model.nm_responsavel' readonly)
    
                                .p-col-12.p-md-3
                                    label.form-label Bairro:
                                    InputText(type='text' v-model='model.nm_bairro' readonly)
    
                                .p-col-12.p-md-3
                                    label.form-label Rua:
                                    InputText(type='text' v-model='model.nm_rua' readonly)
    
                                .p-col-12.p-md-6
                                    label.form-label Complemento:
                                    InputText(type='text' v-model='model.ds_complemento' readonly)
    
                                .p-col-12.p-md-3
                                    label.form-label CEP:
                                    InputText(mask='99999-999' v-model='model.nr_cep_f' readonly)
    
                                .p-col-12.p-md-3
                                    label.form-label Número da Empresa:
                                    InputText(type='text' v-model='model.nr_empresa' readonly)
    
                                .p-col-12.p-md-3
                                    label.form-label Telefone:
                                    InputText(:mask="'(99) 99999999?9'" v-model='model.nr_telefone_f' readonly)
    
                                .p-col-12.p-md-3
                                    label.form-label Email:
                                    InputText(type='text' v-model='model.ds_email' readonly)
    
                                .p-col-12.mt-2.mb-2(v-if="model.id")
                                    .p-grid
                                        .p-col-12.p-md-6.p-lg-3
                                            span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                        .p-col-12.p-md-6.p-lg-3
                                            span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                        .p-col-12.p-md-6.p-lg-3
                                            span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                        .p-col-12.p-md-6.p-lg-3
                                            span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}
    
                    Panel.datatable.mt-3(:header="`Lista de ${model.cd_tipo_empresa === 1 ? 'clientes' : 'correntistas'}`")
                        ProgressBar(v-if='waitingAssociados' mode="indeterminate")
    
                        div(v-else)
                            .p-grid.p-fluid.p-align-end
                                .p-col-6
                                    label.form-label Nome:
                                    .p-inputgroup
                                        InputText(type='text'  v-model='filters.nm_pessoa_fisica' @keyup.enter="filtrarAssociados()")
                                        Button.p-button-danger(
                                            v-if='filters.nm_pessoa_fisica.length'
                                            icon='jam jam-rubber'
                                            @click="filters.nm_pessoa_fisica = ''"
                                        )
    
                                .p-col-4
                                    label.form-label CPF:
                                    .p-inputgroup
                                        InputMask(
                                            :mask="'999.999.999-99'"
                                            v-model="filters.nr_cpf"
                                            @keydown.enter="filtrarAssociados()"
                                            :autoClear="false")
                                        Button.p-button-danger(
                                            v-if='filters.nr_cpf.length'
                                            icon='jam jam-rubber'
                                            @click="filters.nr_cpf = ''"
                                        )
    
                                .p-col-2
                                    Button(
                                        label='Filtrar'
                                        icon='jam jam-search'
                                        :disabled='waitingAssociados'
                                        @click="filtrarAssociados()")
    
                                .p-col-5
                                    label.form-label Data de Inclusão:
                                    .p-inputgroup
                                        Dropdown(
                                            v-model='tipo_data_filter'
                                            :options='options.tipo_data'
                                            optionLabel='label'
                                            optionValue='value'
                                            dataKey='value'
                                            :style="{'width':'30%'}"
                                        )
                                        Calendar(
                                            v-model="filters[tipo_data_filter]"
                                            :dateFormat="tipo_data_filter == 'dt_competencia' ? 'mm/yy' : 'dd/mm/yy'" 
                                            :placeholder="tipo_data_filter == 'dt_competencia' ? 'MM/AAAA' : 'DD/MM/AAAA'"
                                            :view="tipo_data_filter == 'dt_competencia' ? 'month' : 'date'"
                                            :selectionMode="tipo_data_filter == 'dt_competencia' ? 'single' : 'range'"
                                            :locale="pt_br"
                                            :monthNavigator="true"
                                            :yearNavigator="true"
                                            :manualInput="true"
                                            :yearRange="`2020:${esseAno}`"
                                            :style="{'width':'70%'}"
                                            @keydown.enter="filtrarAssociados()"
                                        )
                                        Button.p-button-danger(
                                            v-if='filters[tipo_data_filter]'
                                            icon='jam jam-rubber'
                                            @click="filters[tipo_data_filter] = null"
                                        )
                                .p-col-5
                                .p-col-2 
                                    Button(
                                        label='Limpar Filtros'
                                        icon='jam jam-rubber'
                                        @click="limparFiltros()")
    
                                .p-col-10(v-if='!isAdd')
                                .p-col-2(v-if='!isAdd')
                                    Button(
                                        label='Adicionar'
                                        icon='jam jam-plus'
                                        @click="isAdd = true")
    
                                .p-col-8.buscar-paciente(:class="{ naoVer: !isAdd}")
                                    BuscarPaciente(ref='buscarPaciente' label='Cliente' :cancelarPopup='true',
                                                    @change = 'clienteAlterado = true' 
                                                    @limpa = 'clienteAlterado = false; isAdd = false;' 
                                                    @busca = 'clienteAlterado = true'
                                                )
                                    
                                .p-col-2(v-if="isAdd")
                                    div.ta-center(:style="{'padding-bottom': clienteAlterado? '5%' : '12.6%'}")
                                        ProgressSpinner(v-if='waitingAddC' :style={"width":"45px", "height": "45px"} strokeWidth='4')
                                        Button(
                                            v-else
                                            label='Vincular Correntista'
                                            icon='jam jam-plus'
                                            :disabled='!clienteAlterado'
                                            @click="addCorrentista()")
    
    
                                .p-col-2(v-if="isAdd")
                                    div.ta-center(:style="{'padding-bottom': clienteAlterado? '5%' : '12.6%'}")
                                        Button(
                                            label='Cancelar'
                                            icon='jam jam-close'
                                            @click="isAdd = false; clienteAlterado = false;")
    
                            div(v-if="!listaAssociados.length")
                                p.ta-center Nenhum item encontrado
    
                            div(v-else)
                                DataTable(:value='totalizadores')
                                    Column(
                                        v-for='t in tableData'
                                        :key="t.header"
                                        :headerStyle='t.hStyle'
                                        :header='t.header'
                                        :field='t.field'
                                        :bodyStyle='t.bStyle'
                                        :sortable='t.sort'
                                    )
                                        template(#body='{data}')
                                            b(v-if="t.field == 'count'") {{ data[t.field] }} 
                                            b(v-else v-html='$root.formatPrice(data[t.field])')
    
                                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")
                                DataTable(:value="listaAssociados")
                                    Column(headerStyle="width: 16%;" field="nm_pessoa_fisica" header="Correntista")
                                        template(#body='{data}')
                                            b {{ data.nm_pessoa_fisica }}
                                            br
                                            em 
                                                b CPF: 
                                                span {{ data.nr_cpf_f }}
                                            br
                                            em 
                                                b Tel.: 
                                                span {{ data.nr_telefone_f }}
                                            br
                                            em
                                                b Sexo: 
                                                span {{ data.ie_sexo_f }}
                                    //- Column(headerStyle="width: 5%;" bodyClass="ta-center" field="ie_sexo" header="Sexo")
                                    //- Column(headerStyle="width: 10%;" bodyClass="ta-center" field="nr_telefone_f" header="Telefone")
                                    Column(headerStyle="width: 8%;" header="Aporte" bodyClass="ta-center" field="nr_valor_apurado_f")
                                    Column(headerStyle="width: 8%;" header="Rendimento" bodyClass="ta-center" field="nr_valor_rendido_f")
                                    Column(headerStyle="width: 8%;" header="Utilização" bodyClass="ta-center" field="nr_valor_utilizado_f")
                                    Column(headerStyle="width: 8%;" header="Saldo" bodyClass="ta-center" field="nr_saldo_med_empresa_f")
                                    Column(headerStyle="width: 7%;" header="Tipo" bodyClass="ta-center" field="cd_titular")
                                        template(#body="{data}")
                                            span {{ data.cd_titular? "Dependente" : "Titular" }}
                                    Column(headerStyle="width: 8%;" bodyClass="ta-center" header="Dt. Inclusão" field="dt_inclusao_f")
                                    Column(headerStyle="width: 8%; text-align: center" header="Ações")
                                        template(#body="{data}")
                                            .ta-center
                                                Button.p-button-raised.p-button-rounded.mr-1(
                                                    v-tooltip.top="'Visualizar'"
                                                    type="button"
                                                    icon="jam jam-user"
                                                    @click='setUser(data)'
                                                )
                                                Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                                    v-if='isAdm'
                                                    v-tooltip.top="'Apagar'"
                                                    icon="jam jam-minus-circle"
                                                    @click="dialogApagar = true; dialogApagar_data = data"
                                                )
                                                Button.p-button-raised.p-button-rounded.mr-1(
                                                    v-tooltip.top="'Histórico'"
                                                    type="button"
                                                    icon="jam jam-newspaper"
                                                    @click='setHistoricoDialog(data)'
                                                )
    
                                Paginator(:rows="paginator.per_page" :first="(paginator.page - 1) * paginator.per_page" :totalRecords="paginator.count" @page="onPage")
    
    </template>
    
    <style lang="scss">
    .empresa-visualizar {
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .buscar-paciente{
            &.naoVer {
                display: none;
            }
        }
    }
    </style>
    
    <script>
    import Button from "primevue/button";
    import Column from "primevue/column";
    import Calendar from 'primevue/calendar'
    import Dropdown from 'primevue/dropdown'
    import DataTable from "primevue/datatable";
    import InputMask from "primevue/inputmask";
    import InputText from "primevue/inputtext";
    import Paginator from "primevue/paginator";
    import Panel from "primevue/panel";
    import ProgressBar from "primevue/progressbar";
    import Tooltip from "primevue/tooltip";
    import MostrarPaciente from "./MostrarPaciente"
    import DialogHistoricoCorrentista from "./DialogHistoricoCorrentista.vue"
    import Dialog from 'primevue/dialog'
    import ProgressSpinner from 'primevue/progressspinner'
    import BuscarPaciente from './../Paciente/BuscarPaciente'
    import CustomDataTable from "../CustomComponents/CustomDataTable.vue";
    
    
    import moment from "moment";
    import { Empresa, Paciente } from "./../../middleware";
    import { pCalendarLocale_ptbr } from './../../utils';
    import wsConfigs from "./../../middleware/configs";
    import { fixTelefoneFormat } from "@/utils";
    import * as _ from 'lodash'
    
    export default {
        components: { Button, Column, DataTable, InputMask, InputText, Dialog, ProgressSpinner,
            Paginator, Panel, ProgressBar, Tooltip, MostrarPaciente, BuscarPaciente, DialogHistoricoCorrentista,
            CustomDataTable, Calendar, Dropdown },
        directives: { tooltip: Tooltip },
        watch: {
            "$refs.buscarPaciente": function(value) {
                this.cliente = value.model;
            },
            tipo_data_filter: function (novo, velho) {
                this.filters[velho] = null
            }
        },
        created () {
            let id = parseInt(this.$route.params.id)
            if (!isNaN(id)) this.getEmpresa(id)
            if(this.$route.path.includes("adm")) this.isAdm = true
        },
        data () {
            return {
                model: {
                    nm_razao_social: null,
                    nm_fantasia: null,
                    nr_cnpj: null,
                    nm_responsavel: null,
                    nm_bairro: null,
                    nm_rua: null,
                    ds_complemento: null,
                    nr_cep: null,
                    nr_empresa: null,
                    nr_telefone: null,
                    nr_whatsapp: null,
                    ds_email: null,
                    nm_usuario_cri: null,
                    nm_usuario_edi: null,
                },
                isAdm: false,
                dialogApagar: false,
                dialogApagar_data: null,
                waitingApagar: false,
                dialogHistoricoCorrentista: {
                    visible: false,
                    data: null
                },
                dialogUser: {
                    visible: false,
                    data: null
                },
                tipo_correntista: [
                    { value: "T", label: "Titular"},
                    { value: "F", label: "Filho"}
                ],
                modelUser: {},
                filters: {
                    nm_pessoa_fisica: '',
                    nr_cpf: '',
                    dt_competencia: null,
                    dt_intervalo: null
                },
                options: {
                    tipo_data: [
                        { value: "dt_competencia", label: "Competência"},
                        { value: "dt_intervalo", label: "Intervalo"}
                    ]
                },
                pt_br: pCalendarLocale_ptbr,
                tipo_data_filter: "dt_competencia",
                esseAno: parseInt(moment().format("YYYY")),
                isAdd: false,
                cliente: null,
                waiting: true,
                waitingAssociados: false,
                waitingPaciente: false,
                selectedPai: null,
                waitingAddC: false,
                listaAssociados: [],
                clienteAlterado: false,
                clienteSelecionado:false,
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                params: {},
                totalizadores: [],
                tableData: [
                    {
                        header: 'Total Correntistas', 
                        field: 'count', 
                        hStyle: {
                            width:'15%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {
                        header: 'Total Aportes', 
                        field: 'total_apurado', 
                        hStyle: {
                            width:'17%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {
                        header: 'Total Rendimentos', 
                        field: 'total_rendido', 
                        hStyle: {
                            width:'17%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {
                        header: 'Total Utilizado', 
                        field: 'total_usado', 
                        hStyle: {
                            width:'17%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {
                        header: 'Total Devolvido', 
                        field: 'total_devolvido', 
                        hStyle: {
                            width:'17%',
                            display: 'none'
                        },
                        bStyle: {
                            textAlign: 'center',
                            display: 'none'
                        }
                    },
                    {
                        header: 'Saldo Atual', 
                        field: 'saldo_atual', 
                        hStyle: {
                            width:'17%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                ]
            }
        },
        methods: {
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            onPage (ev) {
                this.filtrarAssociados(ev.page + 1);
            },
            filtrarAssociados(page) {
                if (!this.model.id) return;
    
                this.paginator.page = page || 1;
                let params = {
                    cd_empresa: this.model.id,
                    paginacao: true,
                    page: this.paginator.page,
                    per_page: this.paginator.per_page,
                    ie_associado_med_empresa: true
                };
                Object.keys(this.filters).forEach(key => {
                    if (this.filters[key]) {
                        if (key === 'nr_cpf') 
                            params[key] = this.filters[key].replace(/(\.|-|_)/g, '');
                        else if (key.startsWith('dt_')) {
                            var aux = _.isArray(this.filters[key]) 
                                        ? this.filters[key][1]
                                            ? this.filters[key]
                                            : _.fill([0,0], this.filters[key][0])
                                        : [this.filters[key]]
                            params[key] = _.map(aux, 
                                d => moment(d).format(
                                    this.tipo_data_filter == 'dt_competencia'
                                        ? 'YYYY-MM' 
                                        : 'YYYY-MM-DD'))
                        } else 
                            params[key] = this.filters[key];
                    }
                });
                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params)) {
                    if (!this.waitingAssociados) {
                        this.waitingAssociados = true;
                        setTimeout(() => this.waitingAssociados = false, 300);
                    }
                } else {
                    this.params = Object.assign({}, params);
                    this.getAssociados(params);
                }
            },
            getPaciente(cd_pessoa_fisica) {
                this.waitingPaciente = true;
                Paciente.find(cd_pessoa_fisica, { cd_empresa: this.model.id, ie_associado_med_empresa: true }).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.waitingPaciente = false;
                        this.modelUser = response.data
                        if(this.modelUser.cd_cidade){
                            this.modelUser.nm_estado = this.modelUser.cd_cidade.cd_estado.nm_estado,
                            this.modelUser.nm_cidade = this.modelUser.cd_cidade.nm_cidade
                        }
                        if (this.modelUser.nr_cpf) {
                            this.modelUser.nr_cpf_f = this.modelUser.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            this.modelUser.nr_cpf = this.modelUser.nr_cpf_f
                        }
                        this.modelUser.dt_nascimento_f = moment(this.modelUser.dt_nascimento).format('DD/MM/YYYY')
                        this.modelUser.ie_sexo_f = this.modelUser.ie_sexo == 'M' ? 'Masculino' : 'Feminino'
                        this.modelUser.label = this.modelUser.nr_cpf ? `${ this.modelUser.nm_pessoa_fisica } (CPF: ${ this.modelUser.nr_cpf_f })` :
                            `${ this.modelUser.nm_pessoa_fisica } (Data nasc.: ${ this.modelUser.dt_nascimento_f })`
                        if (this.onSelectPessoaFisica) this.onSelectPessoaFisica()
                    }
                    else{
                        this.$toast.error("Paciente não encontrado")
                    }
                })
            },
            getAssociados(params){
    
                this.waitingAssociados = true
                Empresa.listarAssociados(params).then( response => {
                    this.waitingAssociados = false
                    if (response.status === 200) {
                        response.data.results.forEach(paciente => {
                            paciente.nm_pessoa_fisica = paciente.cd_titular ? `${paciente.nm_pessoa_fisica} (dependente)` : paciente.nm_pessoa_fisica
                            paciente.dt_nascimento_f = moment(paciente.dt_nascimento).format('DD/MM/YYYY')
                            paciente.dt_inclusao_f = moment(paciente.dt_inclusao).format('DD/MM/YYYY')
                            paciente.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[paciente.ie_sexo]
                            if (paciente.nr_telefone) paciente.nr_telefone_f = fixTelefoneFormat(paciente.nr_telefone)
                            if (paciente.nr_cpf) paciente.nr_cpf_f = paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            paciente.nr_valor_apurado_f = this.formatPrice(paciente.nr_valor_apurado)
                            paciente.nr_valor_rendido_f = this.formatPrice(paciente.nr_valor_rendido)
                            paciente.nr_valor_utilizado_f = this.formatPrice(paciente.nr_valor_utilizado)
                            paciente.nr_saldo_med_empresa_f = this.formatPrice(paciente.nr_saldo_med_empresa)
                        })
                        this.listaAssociados = response.data.results
                        this.totalizadores = [_.omit(response.data, ['results'])]
                        this.paginator.count = response.data.count
                    }
                })
            },
            getEmpresa(id) {
                this.waiting = true;
                Empresa.find(id).then(response => {
                    if (response.status === 200) {
                        let keys = Object.keys(this.model);
                        keys.forEach(key => this.model[key] = response.data[key]);
                        this.model.id = response.data.id;
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm');
                        this.model.nr_cnpj_f = this.model.nr_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
                        this.model.nr_cep_f = this.model.nr_cep.replace(/(\d{5})(\d{3})/, '$1-$2');
                        this.model.nr_telefone_f = fixTelefoneFormat(this.model.nr_telefone);
                        if (response.data.dt_atualizado) {
                            this.model.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm');
                        }
                        this.filtrarAssociados();
                    }
                    this.waiting = false;
                })
            },
            setUser(data){
                this.getPaciente(data.id);
                this.selectedPai = data.cd_titular
                this.dialogUser.visible = true;
                this.dialogUser.data = data;
            },
            setHistoricoDialog(data) {
                this.dialogHistoricoCorrentista.visible = true
                this.dialogHistoricoCorrentista.data = data
            },
            excluirCorrentista() {
                let dataSend ={
                    cd_empresa: this.model.id,
                    correntistas: [this.dialogApagar_data.id]
                }
                this.waitingApagar = true;
                Empresa.excluirCorrentista(dataSend).then(response => {
                    this.waitingApagar = false;
                    if(([200,201]).includes(response.status)){
                        this.listaAssociados = this.listaAssociados.filter(item => item.id != this.dialogApagar_data.id);
                        this.$toast.success("Usuário excluido com sucesso");
                        this.dialogApagar_data = null;
                        this.dialogApagar = false;
                    }else if(response.status == 400){
                        this.$toast.error(response.data.detail, 6000);
                    }
                    else this.$toast.error("Erro na exclusão do Usuário");
                })
                    
            },
            addCorrentista(){
                let dataSend = {
                    cd_empresa: this.model.id,
                    cd_pessoa_fisica: this.$refs.buscarPaciente.model.id
                }
                console.log(dataSend, this.$refs.buscarPaciente.model)
                this.waitingAddC = true;
                Empresa.adicionarCorrentista(dataSend).then(response => {
                    this.waitingAddC = false;
                    if(([200,201,204]).includes(response.status)){
                        this.listaAssociados.push({
                            id: this.$refs.buscarPaciente.model.id,
                            nm_pessoa_fisica: this.$refs.buscarPaciente.model.nm_pessoa_fisica,
                            nr_cpf_f: this.$refs.buscarPaciente.model.nr_cpf_f,
                            dt_nascimento_f: this.$refs.buscarPaciente.model.dt_nascimento_f,
                            ie_sexo_f: this.$refs.buscarPaciente.model.ie_sexo_f,
                            cd_pai: this.$refs.buscarPaciente.model.cd_pai,
                            cd_titular: this.$refs.buscarPaciente.model.cd_pai,
                            nr_saldo_med_empresa: this.$refs.buscarPaciente.model.nr_saldo,
                            nr_saldo_med_empresa_f: this.formatPrice(this.$refs.buscarPaciente.model.nr_saldo),
                            ds_email: this.$refs.buscarPaciente.model.ds_email,
                            nr_telefone: this.$refs.buscarPaciente.model.nr_telefone
                        })
                        this.isAdd = false; 
                        this.clienteAlterado = false;
                    }
                })
            },
            limparFiltros() {
                _.each(this.filters, (val, key) => {
                    if(_.isString(val))
                        this.filters[key] = ''
                    else 
                        this.filters[key] = null
                })
                this.filtrarAssociados()
            }
        }
    }
    </script>
    