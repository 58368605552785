<template lang="pug">
Dialog.dialogPaciente(
        header="Visualizar Paciente"
        :visible.sync='show'
        :class="{ 'overflow-visible':dialogConsultarCEP, 'dialogPaciente':true }"
        :modal='true')

        .waitingPaciente(v-show='waitingPaciente')
            ProgressSpinner(strokeWidth='2')

        div(v-show='!waitingPaciente')
            form(@submit.prevent='handleSubmit()')
                .p-grid.p-fluid.p-align-end

                    .p-col-12.p-md-6()
                        label.form-label Nome:
                        InputText.text-readonly(readonly type='text' v-model='model.nm_pessoa_fisica')

                    .p-col-12.p-md-3
                        label.form-label CPF:
                        InputMask.text-readonly(readonly type='tel' v-model='model.nr_cpf' mask='999.999.999-99' :placeholder="model.responsavel ? '(Opcional)' : ''")

                    .p-col-12.p-md-3
                        label.form-label Data de nascimento:
                        InputMask.text-readonly(readonly type='tel'  v-model='model.dt_nascimento' mask='99/99/9999' placeholder='dd/mm/aaaa')
                       

                    .p-col-12.p-md-4
                        label.form-label Sexo:
                        InputText(readonly type='text' v-model='model.ie_sexo_f' )
                        //- SelectButton.input-sexo(disabled type='text' v-model='model.ie_sexo' :options='options.ie_sexo' optionLabel='text' optionValue='value')
                       
                    .p-col-12.p-md-5
                        label.form-label E-mail:
                        InputText(readonly type='text' v-model='model.ds_email' )

                    .p-col-12.p-md-3
                        label.form-label Telefone:
                        InputMask.text-readonly(readonly  type='tel' v-model='model.nr_telefone' mask='(99) 99999999?9')

                    .p-col-12(v-if='model.cd_titular')
                        h4.text-box.my-2
                            span Titular
                            .divider
                        .p-inputgroup
                            span <li> {{ `&nbsp;${model.cd_titular.nm_pessoa_fisica} - CPF: ${model.cd_titular.nr_cpf.slice(0, 3)}.${model.cd_titular.nr_cpf.slice(3, 6)}.${model.cd_titular.nr_cpf.slice(6, 9)}-${model.cd_titular.nr_cpf.slice(9)}` }} </li>
                    .p-col-12(v-else-if='model.dependentes?.length > 0')
                        h4.text-box.my-2
                            span Dependentes
                            .divider
                        .p-inputgroup(v-if='model.dependentes?.length > 0' v-for="dependente in model.dependentes")
                            span <li>{{ `${dependente.nm_pessoa_fisica} - CPF: ${dependente.nr_cpf.slice(0, 3)}.${dependente.nr_cpf.slice(3, 6)}.${dependente.nr_cpf.slice(6, 9)}-${dependente.nr_cpf.slice(9)}` }}</li>
                    //- .p-col-12(v-if="isTitular")
                    //-     h4.text-box.my-2
                    //-         span Titular
                    //-         .divider
                    //-     .p-inputgroup
                    //-         span {{ isTitular }}
                            
                    .p-col-12(v-if='!isAdd && !isTitular')
                        ProgressSpinner(v-if="waitingAdd" strokeWidth='6')
                        .ta-right(v-else) 
                            Button(
                                label='Adicionar dependente'
                                icon='jam jam-plus'
                                type="button"
                                style='width: 30%'
                                @click='isAdd = true;'
                                )
                    .p-col-12.p-md-8.buscar-paciente(v-if="!isTitular" :class="{ naoVer: !isAdd}" )
                            BuscarPaciente(ref='buscarPaciente' label='Cliente' :cancelarPopup='true',
                                            @change = 'clienteAlterado = true' 
                                            @limpa = 'teste()' 
                                            @busca = 'clienteAlterado = true'
                                            :ocultarNomeLimpar = "true" 
                                        )
                    .p-col-4(v-if="isAdd")(:style="{'padding-bottom': clienteAlterado? '2.5%' : '6%'}")
                        div.ta-center
                            label.form-label Tipo de Parentesco:
                            Dropdown(v-model='ie_tipo_parentesco' :options='options.ie_tipo_parentesco'
                            optionLabel='text' optionValue='value' placeholder='Selecione' filter)
                    
                    .p-col-12.p-md-12.ta-center(v-if="isAdd")
                        ProgressSpinner(v-if='waitingAdd' :style={"width":"45px", "height": "45px"} strokeWidth='4')
                        Button(
                            v-else
                            label='Vincular Correntista'
                            icon='jam jam-plus'
                            type="button"
                            style="max-width: 30%"
                            :disabled='!clienteAlterado || !ie_tipo_parentesco'
                            @click="addCorrentista()")
                        
                       
                    .p-col-12.ta-right(v-if="isAdd")
                        Button(
                            label='Cancelar'
                            icon='jam jam-close'
                            type="button"
                            style='max-width:15.5%'
                            @click="isAdd = false; clienteAlterado = false;")
                    .p-col-12
                        .p-grid.p-fluid.p-align-end.p-justify-end

                            .p-col-12
                                h4.text-box
                                    span Endereço
                                    .divider

                            .p-col-12.p-md-4
                                label.form-label CEP:
                                InputMask.text-readonly(type='tel' v-model='model.nr_cep' mask='99999-999' @blur='searchCEP()' readonly)

                                .waitingCEP(v-if='waitingCEP')
                                    ProgressSpinner(strokeWidth='6')
                                //- a.link-naoseimeucep(v-if='!medEmpresa' href='javascript:;' @click="naoSeiMeuCEP_open()") Não sei meu CEP

                            .p-col-12.p-md-4
                                label.form-label Estado:
                                InputText(type='text' v-model='model.nm_estado' readonly)
                                
                                
                            .p-col-12.p-md-4
                                label.form-label Município:
                                InputText(type='text' v-model='model.nm_cidade' readonly)
                               
                            .p-col-12.p-md-4
                                label.form-label Bairro:
                                InputText(type='text' v-model='model.nm_bairro' readonly)
                               

                            .p-col-12.p-md-4
                                label.form-label Rua/Logradouro:
                                InputText(type='text' v-model='model.nm_rua' readonly)
                              
                            .p-col-12.p-md-4
                                label.form-label Número:
                                InputText(v-model='model.nr_numero' type='tel' readonly)
                              
                            .p-col-12.mt-3
                                label.form-label Observações:
                                Textarea.textarea-informacoes(readonly v-model='model.ds_observacao'  :autoResize="true" rows="5")

</template>

<style lang="scss">
    .dialogPaciente.overflow-visible {
        .p-dialog-content {
            overflow: visible !important;
        }
        .buscar-paciente{
            &.naoVer {
                display: none;
            }
        }
    }
    .p-dialog-mask {
        //align-items: flex-start;
        padding-top: 40px;
    }
    .dialogPaciente {
        width: 96%;
        max-width: 720px;
        .waitingPaciente {
            text-align: center;
            .p-progress-spinner {
                margin: 40px 0;
                width: 60px;
                height: auto;
            }
        }
        .dropdown-buscaResponsavel {
            .p-dropdown-label {
                font-weight: 700 !important;
            }
        }
        .text-obs-responsavel {
            color: #888;
            font-size: 12px;
        }

        .text-readonly[readonly=readonly] {
            font-weight: 700;
            background-color: #ddd;
            color: #666;
        }
        .text-box {
            text-align: center;
            font-size: 16px;
            margin: 0;
            position: relative;
            span {
                background-color: #fff;
                display: inline-block;
                z-index: 1;
                position: relative;
                padding: 0 10px;
                color: #888;
            }
            .divider {
                // z-index: 9;
                position: absolute;
                top: 12px;
                left: 0;
                right: 0;
                height: 1px;
                background-color: #888;
            }
        }
        .waitingCEP {
            position: absolute;
            top: 34px;
            right: 20px;
            .p-progress-spinner {
                width: 20px;
                height: auto;
            }
        }
        .waitingForm {
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            .p-progress-spinner {
                width: 28px;
                height: auto;
            }
        }
        .link-naoseimeucep {
            position: absolute;
            right: 8px;
            bottom: -10px;
            font-size: 13px;
            font-weight: 700;
        }
        .datatable-cep {
            td { font-size: 13px; }
            max-height: 200px;
            overflow-y: auto;
        }
        .waitingCEPDialog {
            width: 25px;
            height: auto;
        }
        .p-dropdown.p-disabled .p-inputtext, .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
    }
</style>

<script>
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import Textarea from 'primevue/textarea'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import ProgressSpinner from 'primevue/progressspinner'
    import ProgressBar from 'primevue/progressbar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import { Utils } from './../../middleware'
    import Methods from '../Paciente/Methods'
    import BuscarPaciente from './../Paciente/BuscarPaciente'
    import { Empresa, DominioValor } from "./../../middleware";



    export default {
        components: { Dialog, InputText, InputMask, Button, Dropdown, SelectButton,
            Tooltip, ProgressSpinner, ProgressBar, DataTable, Column, Textarea, BuscarPaciente },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            this.hasError = false
            Utils.getUFs().then(response => {
                this.options.ufs.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
                if (response.status == 200) this.options.ufs = response.data
                let usuario = JSON.parse(localStorage.getItem('usuario'))
                this.usuarioNivel = usuario.nivel
                this.waiting = false
            })

            DominioValor.findAll({ds_mnemonico: 'PARENTESCO_INTEGRACAO'}).then((response) => {
                if(response.status === 200) {
                    response.data['PARENTESCO_INTEGRACAO'].valores.forEach(item => {
                        this.options.ie_tipo_parentesco.push({value: item.ie_valor, text: item.ds_valor})
                    })
                }
            })
        },
        props: ['dialogPaciente', 'model', "isTitular",  'waitingPaciente', "empresaId"],
        data () {
            return {
                hasError: false,
                buscaResponsavel: {
                    field: 'nm_pessoa_fisica',
                    value: null,
                    result: []
                },
                options: {
                    ie_sexo: [
                        { text: 'Masculino', value: 'M' },
                        { text: 'Feminino', value: 'F' }
                    ],
                    buscaResponsavel: [
                        { text: 'Nome:', value: 'nm_pessoa_fisica' },
                        { text: 'CPF:', value: 'nr_cpf' }
                    ],
                    municipiosEndereco: [],
                    cep_municipiosEndereco: [],
                    ufs: [],
                    ie_tipo_parentesco: []
                },
                dialogConsultarCEP: false,
                dialogConsultarCEP_data: {
                    logradouro: null,
                    cidade: null,
                    estado: null,
                    result: []
                },
                waiting: true,
                ie_tipo_parentesco: null,
                waitingResponsavel: false,
                waitingTipoParentesco: false,
                waitingAdd: false,
                waitingCEPDialog: false,
                waitingCarteira: false,
                waitingCEP: false,
                waitingForm: false,
                isAdd: false,
                clienteAlterado: false,
                submitted: false,
                isBuscaPacienteModal: true,
                usuarioNivel: 0,
                dCadastro: false,
                pai: null
            }
        },
        computed: {
            show: {
                get() { return this.dialogPaciente },
                set(value) { if (!value) { 
                    this.isAdd = false; 
                    this.clienteAlterado = false;
                    this.$emit('close') 
                }}
            }
        },
        methods: {
            teste(){ 
                this.isAdd = false;
                this.clienteAlterado = false;
            },
            getMunicipiosEndereco (modal = false) {
                if (modal) Methods.getMunicipiosEnderecoModal(this)
                else Methods.getMunicipiosEndereco(this)
            },
			imprimirBoasVindas () { Methods.imprimirBoasVindas(this) },
            imprimirCarteira () { Methods.imprimirCarteira(this) },
            naoSeiMeuCEP_open () { Methods.naoSeiMeuCEP_open(this) },
            onRowSelectCEP (ev) { Methods.onRowSelectCEP(this, ev) },
            getResponsavel () { Methods.getResponsavel(this) },
            handleSubmitCEP () { Methods.handleSubmitCEP(this) },
            // handleSubmit () {
            //     if(this.medEmpresa) {
            //         this.$toast.error('Não é possível alterar dados relacionados ao MedClub Empresa')
            //     } else Methods.handleSubmit(this)
            // },
            searchCEP () { Methods.searchCEP(this) },
            addCorrentista(){
                let dataSend = {
                    cd_empresa: this.empresaId,
                    cd_pessoa_fisica: this.$refs.buscarPaciente.model.id,
                    cd_titular: this.model.id,
                    ie_tipo_parentesco: this.ie_tipo_parentesco
                }
                this.waitingAdd = true;
                Empresa.adicionarCorrentista(dataSend).then(response => {
                    this.waitingAdd = false;
                    if(([200,201,204]).includes(response.status)){
                        // this.model.dependentes.push(this.$refs.buscarPaciente.model)
                        this.$toast.success("Dependente adicionado com sucesso");
                        this.$router.go(0)
                    }else this.$toast.error("Erro ao cadastrar dependente")
                })
            }
        }
    }
</script>
